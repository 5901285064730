.comparision_container{

    .comparision_container_v_1{
        padding:0 2rem;
    }
    .v_2{
        background: #E6F3F2;
        padding:4rem;
        display: flex;
        gap:2rem;
        justify-content: center;
    }

    .v_2 img{
        /* width: 100%; */
    }

    .v_2_1{
        width: 70%;
        display: flex;
        flex-direction: column;
        gap:1rem;
        justify-content: center;
    }

    .v_3{
        background: #FFEAE9;
        padding:4rem;
        display: flex;
        gap:2rem;
        text-align: center;
    }

    .v_3 img{
        /* width:100%; */
    }

    .v_3_1{
        width:70%;
        display: flex;
        flex-direction: column;
        gap:1rem;
        justify-content: center;
    }


    @media screen and (max-width:450px) {
        .v_2{
            flex-direction: column;
        }

        .v_2_1{
            width: 100%;
        }

        .v_3{
            flex-direction: column-reverse;
        }

        .v_3_1{
            width: 100%;
        }
        
    }
}