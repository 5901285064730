.primary_resume_main_con {
    border: 2px solid #187C8B;
    border-radius: 45px;
    padding: 30px;

    .main_heading {
        font-family: Lufga;
        font-size: 36px;
        font-weight: 600;
        text-align: center;

    }

    .second_heading {
        font-family: Lufga;
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        color: #187C8B;
    }

    .primaryResumeLabel {
        border: 2px dashed #187C8B;
        padding: 20px;
        min-height: 200px;
        border-radius: 15px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        /* justify-content: center; */
        align-items: center;

        .img_con {
            width: 100px;
            height: 100px;
            border: 1px solid red;
        }

        .input_box_design {
            display: flex;
            width: 100px;
            border-radius: 10px;
            align-items: center;
            justify-content: center;
            font-size: 30px;
            height: 100px;
            color: #187C8B;
            border: 2px dashed #187C8B;
        }

        .input_img_icon {
            font-size: 40px;
            color: #187C8B;
        }
    }

    .complete_text {
        font-family: Lufga;
        font-size: 20px;
        font-weight: 600;
        line-height: 47.58px;
        text-align: left;

    }

    .upload_text {
        font-family: Lufga;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.012em;
        text-align: left;

    }

    .addChild_btn {
        all: unset;
        background-color: #F5F6FA;
        font-family: Lufga;
        font-size: 14px;
        cursor: pointer;
        font-weight: 400;
        line-height: 18.27px;
        letter-spacing: 0.30000001192092896px;
        text-align: left;
        display: flex;
        padding: 12px 24px;
        align-items: center;
        border-radius: 70px;
        gap: 10px;
        justify-content: center;

    }

    .pre_btn {
        all: unset;
        color: #187C8B;
        font-family: Lufga;
        font-size: 18px;
        font-weight: 500;
        line-height: 23.49px;
        text-align: left;
        margin-right: 50px;
        cursor: pointer;
    }



    @media (max-width:815px) {
        .main_heading {
            font-size: 30px;
            font-weight: 600;
        }
    
        .second_heading {
            font-size: 20px;
            font-weight: 600;
        }
        .complete_text {
            font-size: 16px;
            font-weight: 600;
            line-height: 25px;
        }
    }


    @media (max-width:600px) {
        .addChild_btn {
            all: unset;
            background-color: #F5F6FA;
            font-family: Lufga;
            font-size: 12px;
            cursor: pointer;
            font-weight: 400;
            line-height: 18.27px;
            letter-spacing: 0.30000001192092896px;
            text-align: left;
            display: flex;
            padding: 8px 15px;
            align-items: center;
            border-radius: 40px;
            gap: 10px;
            justify-content: centers;
    
        }
    }
    @media (max-width:500px) {
        .main_heading {
            font-size: 25px;
            font-weight: 600;
        }
    
        .second_heading {
            font-size: 16px;
            font-weight: 600;
        }
    }










}


.v_resume_scalling {
    width: 100%;
    margin: 0 auto;
    transform: scale(1.0);
    display: flex;
    flex-direction: row;
    /* border: 1px solid red; */
}

.v_child_Container {
    display: grid;
    width: 100%;
    grid-template-columns: 33.33% 33.33% 33.33%;
}

.v_img_containerR {
    width: 50%;
    height: auto;
    display: flex;
    align-items: unset;
}

.v_top_container {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.v_codeheading {
    font-weight: 600;
    font-size: 24px;
    color: black;
    font-family: 'Lufga';
}

.v_codeSub {
    font-weight: 600;
    font-size: 14px;
    color: rgba(24, 124, 139, 1);
    font-family: 'Lufga';
}

.v_logo_img {
    width: 140px;
}

.v_headingg {
    margin-top: 30px;
    font-weight: 600;
    font-size: 18px;
    color: rgba(24, 124, 139, 1);
    font-family: Lufga;
    border-bottom: 2px solid rgba(24, 124, 139, 1);
    width: 220px;
    padding-bottom: 3px;
}
.v_headingg2 {
    margin-top: 30px;
    font-weight: 600;
    font-size: 18px;
    color: rgba(24, 124, 139, 1);
    font-family: Lufga;
    border-bottom: 2px solid rgba(24, 124, 139, 1);
    width: 300px;
    padding-bottom: 3px;
}

.v_listContent {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.v_listHeading {
    font-weight: 600;
    font-size: 14px;
    font-family: 'Lufga';
}

.v_listText {
    font-weight: 400;
    font-size: 14px;
    font-family: 'Lufga';
}


.v_childHeading {
    font-size: 16px;
    font-weight: 600;
    color: rgba(24, 124, 139, 1);
    font-family: 'Lufga';
}

.v_topBox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 20px;
    background: rgba(24, 124, 139, 1);
}

.v_leftBox {
    position: absolute;
    top: 0;
    left: 0;
    width: 17px;
    height: 350px;
    background: rgba(24, 124, 139, 1);
}

.v_rightBox {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 100px;
    background: rgba(24, 124, 139, 1);
}

.v_bottomBox {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 350px;
    height: 20px;
    background: rgba(24, 124, 139, 1);
}
.v_maincontainer {
    width: 100%;
    height: auto;
    position: relative;
    padding: 25px;
    background: linear-gradient(251.07deg, #FFFFFF 35.06%, #E8F9FA 95.96%);
}
.v_maincontainer2 {
    width: 100%;
    height: auto;
    position: relative;
    padding: 35px;
    background: linear-gradient(251.07deg, #FFFFFF 35.06%, #E8F9FA 95.96%);
    margin-top: 0;
}

.v_toprightC{
    width: 60%;
    padding: 20px 20px;
}

@media (max-width:1580px) {
    .v_child_Container {
        display: grid;
        width: 100%;
        grid-template-columns: 50% 50%;
    }
}

@media (max-width:1300px) {
    .v_child_Container {
        display: grid;
        width: 100%;
        grid-template-columns: 33.33% 33.33% 33.33%;
    }

    .v_resume_scalling {
        flex-direction: column;
        max-width: 905px;
    }
}

@media (max-width:1035px) {
    .v_resume_scalling {
        width: 100%;
    }
}

@media (max-width:815px) {
    .v_child_Container {
        display: grid;
        width: 100%;
        grid-template-columns: 50% 50%;
    }
}

@media (max-width:900px) {
    .primary_resume_main_con {
        border: 2px solid #187C8B;
        border-radius: 25px;
        padding: 10px;
    }
}

@media (max-width:750px) {
    .v_child_Container {
        grid-template-columns: 49% 49%;
    }

    /* .v_img_containerR{
        width: 50%;
        height: 300px;
        align-items: center;
        margin-top: 20%;
    } */
}

@media (max-width:680px) {

    .v_codeheading {
        font-weight: 600;
        font-size: 18px;
        color: black;
        font-family: 'Lufga';
    }

    .v_codeSub {
        font-weight: 600;
        font-size: 12px;
        color: rgba(24, 124, 139, 1);
        font-family: 'Lufga';
    }

    .v_logo_img {
        width: 100px;
    }

    .v_headingg {
        margin-top: 20px;
        font-weight: 600;
        font-size: 13px;
        border-bottom: 1px solid rgba(24, 124, 139, 1);
        width: 160px;
    }
    .v_headingg2 {
        margin-top: 20px;
        font-weight: 600;
        font-size: 13px;
        border-bottom: 1px solid rgba(24, 124, 139, 1);
        width: 220px;
    }

    .v_img_containerR {
        width: 50%;
        height: 400px;
        align-items: center;
        /* margin-top: 10%; */
    }

    .v_listContent {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
    }

    .v_listHeading {
        font-weight: 600;
        font-size: 12px;
        font-family: 'Lufga';
    }

    .v_listText {
        font-weight: 400;
        font-size: 12px;
        font-family: 'Lufga';
    }

    .v_childHeading {
        font-size: 13px;
        font-weight: 600;
        color: rgba(24, 124, 139, 1);
        font-family: 'Lufga';
    }

    .v_topBox {
        position: absolute;
        top: 0;
        left: 0;
        width: 80px;
        height: 10px;
        background: rgba(24, 124, 139, 1);
    }
    .v_leftBox {
        position: absolute;
        top: 0;
        left: 0;
        width: 10px;
        height: 250px;
        background: rgba(24, 124, 139, 1);
    }
    .v_rightBox {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 10px;
        height: 80px;
        background: rgba(24, 124, 139, 1);
    }
    .v_bottomBox {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 250px;
        height: 10px;
        background: rgba(24, 124, 139, 1);
    }

    .v_maincontainer {
        padding: 15px;
    }
    .v_maincontainer2 {
        padding: 25px;
        margin-top: 20px;
    }

    .v_toprightC{
        padding: 0 10px;
    }

}
@media (max-width:480px) {

    .v_codeheading {
        font-weight: 600;
        font-size: 14px;
        color: black;
        font-family: 'Lufga';
    }

    .v_codeSub {
        font-weight: 600;
        font-size: 10px;
        color: rgba(24, 124, 139, 1);
        font-family: 'Lufga';
    }

    .v_logo_img {
        width: 80px;
    }

    .v_headingg {
        margin-top: 15px;
        font-weight: 600;
        font-size: 11px;
        padding-bottom: 1px;
        border-bottom: 1px solid rgba(24, 124, 139, 1);
        width: 140px;
    }
    .v_headingg2 {
        margin-top: 15px;
        font-weight: 600;
        font-size: 11px;
        padding-bottom: 1px;
        border-bottom: 1px solid rgba(24, 124, 139, 1);
        width: 190px;
    }

    .v_img_containerR {
        width: 50%;
        height: 200px;
        align-items: center;
        /* margin-top: 10%; */
    }

    .v_listContent {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
    }

    .v_listHeading {
        font-weight: 600;
        font-size: 10px;
        font-family: 'Lufga';
    }

    .v_listText {
        font-weight: 400;
        font-size: 10px;
        font-family: 'Lufga';
    }

    .v_childHeading {
        font-size: 11px;
        font-weight: 600;
        color: rgba(24, 124, 139, 1);
        font-family: 'Lufga';
    }

    .v_topBox {
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;
        height: 7px;
        background: rgba(24, 124, 139, 1);
    }
    .v_leftBox {
        position: absolute;
        top: 0;
        left: 0;
        width: 7px;
        height: 150px;
        background: rgba(24, 124, 139, 1);
    }
    .v_rightBox {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 7px;
        height: 60px;
        background: rgba(24, 124, 139, 1);
    }
    .v_bottomBox {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 150px;
        height: 7px;
        background: rgba(24, 124, 139, 1);
    }

    .v_maincontainer {
        padding: 15px;
    }
    .v_maincontainer2 {
        padding: 25px;
        margin-top: 20px;
    }

}

/* @media (max-width:590px){
    .v_child_Container{
        grid-template-columns: auto;
    }
    .v_top_container{
        flex-direction: column;
    }
} */

.input-text{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.input-text-main{
    background-color: #F3F8F9;
}