.width_not_match{
    width: 47.5%;
}

@media (max-width:576px)
{
    .width_not_match{
        width: 100%;
    }
}


.comparision_container{

    .comparision_container_v_1{
        padding:0 2rem;
    }
    
    .v_2{
        background: #E6F3F2;
        padding:4rem;
        display: flex;
        gap:2rem;
        justify-content: center;
    }


    .v_2_1{
        width: 70%;
        display: flex;
        flex-direction: column;
        gap:1rem;
        justify-content: center;
    }

    .v_3{
        background: #FFEAE9;
        padding:4rem;
        display: flex;
        gap:2rem;
        text-align: center;
    }

  

    .v_3_1{
        width:70%;
        display: flex;
        flex-direction: column;
        gap:1rem;
        justify-content: center;
    }


    @media screen and (max-width:450px) {
        .v_2{
            flex-direction: column;
        }

        .v_2_1{
            width: 100%;
        }

        .v_3{
            flex-direction: column-reverse;
        }

        .v_3_1{
            width: 100%;
        }
        
    }
}


/* .heading{
    font-size: 36px;
    font-weight: 500;
    color: black;
    text-align: center;
}

.bgImg{
    background: url(/public/images/image_2024_03_05T12_37_23_785Z.png);
    background-size: cover;
    background-position: center;
    border-radius: 25px;
}


.SaveButton {
    border: none;
    background: transparent;
    border-radius: 11px;
    padding: 15px 30px;
    color: #187C8B;
    font-weight: 500;
    text-align: center;
    font-size: 18px;
}

.closeIcon{
    font-size: 24px;
    font-weight: 700;
    color: #187c8b;
    cursor: pointer;
}
 */
