.DialogParent {
    width: 100%;
    font-family: 'Lufga', sans-serif;
    border-radius: 6px;

    .SignUphedaing {
        font-size: 2.25rem;
        font-weight: 700;
        color: #000000;
        font-family: 'Lufga', sans-serif;
        ;
    }


    .form-label {
        font-family: 'Lufga', sans-serif !important;
        font-size: 1rem;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.012em;
        text-align: left;
        color: #101828;

        ;



    }



  

    .EyeView {
        position: relative;
    }

    /* .View {
        position: absolute;
        top: 55%;
        right: 10%;
    } */

    .form-check-label {
        color: black;
        font-weight: 400;
        font-size: 1rem;
    }

    .form-control-placeholder::placeholder {
        font-size: 0.875rem;
    }

    .form-control {
        border: 1px solid rgb(177, 174, 174);
        padding: 5px 10px;
    }

    .form-select {
        border: 1px solid rgb(177, 174, 174);
    }

    .form-check-input {
        border: 1px solid rgb(177, 174, 174);
    }

    .ButtonForm {
        display: flex;
        justify-content: end;
        gap: 10px;
        margin-top: 30px;
    }

    .SaveButton {
        background-color: #187C8B;
        border-radius: 11px;
        padding: 8px 30px;
        color: white;
        font-weight: 500;
        text-align: center;
        border: none;
    }

    .CencelButton {
        background-color: transparent;
        border-radius: 11px;
        border: none;
        padding: 8px 25px;
        color: #187C8B;
        font-size: 1rem;
        font-weight: 500
    }

    .TermColor {
        color: #187C8B;
        font-size: 1rem;
        font-weight: 400;
        text-align: left;
    }

    .form-check-label {
        color: #000000;

        font-size: 1rem;
        font-weight: 400;

        text-align: left;

    }

    .TextCustomBottom {

        font-weight: 500;
        line-height: 24px;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 24px;
        color: black;



    }

    .Create {
        font-weight: 500;
        line-height: 24px;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 24px;
        color: #187C8B;

    }

    .spanaPara {
        font-weight: 500;
        line-height: 24px;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 24px;
    }

    .BackLogin {
        font-weight: 500;
        line-height: 24px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 24px;
        color: #187C8B;
    }

    .ForgetPassword {
        color: #187C8B;
        font-weight: 400;
    }

    .ButtonHowDoes{
        padding: 21px 26px;
        border-radius: 12px;
        background-color: #ba0000cd !important;
        font-family: Lufga;
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: center;
        color: white;
        border: none;
        margin-top: 20px;

    }

    @media only screen and (max-width: 640px) {

       

        .SignUphedaing {
            font-size: 2rem;
            font-weight: 500;
            color: #000000;
            font-family: 'Lufga', sans-serif;
            ;
        }

        .LabelHeading {
            font-family: Lufga;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.012em;
            text-align: left;
            color: #000000;

        }

    }



}
.LoaderCustom{
    background-color: unset !important;
    box-shadow: unset !important;
}

/* .p-dialog {
    background: transparent!;
    .p-dialog-content{
        background: transparent!;
    }
} */