

.adoptingParentSuccess_main_con {
    font-family: Lufga;
    padding: 10px;
    border-radius: 25px;
    background: linear-gradient(to bottom right, #AEF2EF, #ffffff, #ffffff, #ffffff, #AEF2EF);
    /* margin: 10px; */

    .section_con {
        padding: 30px;
        background-image: url("/public/images/APSuccessImg1.png");
        /* background-size: 100; */
        background-size: contain;
        background-position: right center;
        background-color: #f6f6f6 !important;
        height: 350px;
        background-repeat: no-repeat;


        border-radius: 25px;
        background-color: white;

        .success_line_2 {
            font-size: 2rem;
            font-weight: 600;
            line-height: 56.47px;
            text-align: left;

        }

        .primary_text {
            font-size: 2rem;
            font-weight: 600;
            color: #187C8B;
        }

        @media(max-width:1300px) {
            .success_line_2 {
                font-size: 1.5rem;
                font-weight: 600;
                line-height: 30px;
                text-align: left;

            }

            .primary_text {
                font-size: 1.5rem;
                font-weight: 600;
                color: #187C8B;
                /* margin-top: 5%; */
            }

        }

        @media(max-width:690px) {
            .success_line_2 {
                font-size: 1.2rem;
                font-weight: 600;
                line-height: normal;
                text-align: left;

            }

            .primary_text {
                font-size: 1.2rem;
                font-weight: 600;
                color: #187C8B;
                /* margin-top: 5%; */
            }

        }

        @media(max-width:500px) {
            .success_line_2 {
                font-size: 1rem;
                font-weight: 600;
                line-height: normal;
                text-align: left;

            }

            .primary_text {
                font-size: 1rem;
                font-weight: 600;
                color: #187C8B;
                /* margin-top: 5%; */
            }

        }
    }



    .section_two {
        display: grid;
        margin-top: 20px;
        grid-template-columns: 48% 48%;
        justify-content: space-between;
        /* gap: 30px; */


        .left_con {
            border-radius: 40px;
            background-color: #FABBB61A;
            padding: 5%;

            .text_1 {
                font-family: Lufga;
                font-size: 2.5rem;
                font-weight: 600;
                line-height: 56.47px;
                text-align: left;

            }
            .primary_text{
                font-family: Lufga;
                font-size: 2.5rem;
                font-weight: 600;
                color: #187C8B;
            }
            

            .text_2 {

                font-family: Lufga;
                font-size: 1.563rem;
                font-weight: 500;
                line-height: 56.47px;
                text-align: left;

            }


            .left_img_con {
                display: flex;
                align-items: end;
                padding-bottom: 20px;
                justify-content: center;
                background-image: url("/public/images/APSuccessImg2.png");
                background-size: contain;
                background-position: bottom center;
                background-repeat: no-repeat;

            }

            @media(max-width:1400px) {
                .text_1 {
                    font-size: 2rem;
                    line-height: 45px;
                }

                .text_2 {
                    font-size: 1.563rem;
                    line-height: 45px;
                }

            }

            @media(max-width:1300px) {
                .text_1 {
                    font-size: 1.5rem;
                    line-height: 40px;
                }

                .text_2 {
                    font-size: 1.4rem;
                    line-height: 40px;
                }

            }

            @media(max-width:900px) {
                .text_1 {
                    font-size: 1.4rem;
                    line-height: 30px;
                }

                .text_2 {
                    line-height: 30px;
                    font-size: 1.3rem;
                }

            }

            @media(max-width:500px) {
                .text_1 {
                    font-size: 1.1rem;
                    line-height: normal;
                }

                .text_2 {
                    line-height: normal;
                    font-size: 1rem;
                }

            }




        }



        .right_con {
            .sample_profile_text {
                font-family: Lufga;
                font-size: 2rem;
                font-weight: 500;
                line-height: 61.55px;
                color: #187C8B;
                border-radius: 20px;
                padding: 15px 30px;
                background-color: #e8f2f3;
            }

            .download_sample_text {
                background-color: #F5F6FA;
                font-family: Lufga;
                font-size: 18px;
                border-radius: 20px;
                font-weight: 600;
                line-height: 23.49px;
                text-align: center;
                padding: 15px 30px;
                color: black;
                display: flex;
                align-items: center;
                justify-content: center;

            }
            @media(max-width:1300px) {
                .sample_profile_text {
                    font-size: 1.5rem;
                    line-height: 50px;
                    padding: 15px 30px;
                }
            }
            @media(max-width:900px) {
                .sample_profile_text {
                    font-size: 1.5rem;
                    line-height: 40px;
                    padding: 10px 20px;
                    border-radius: 15px;
                }

                .download_sample_text {
                    font-size: 17px;
                    border-radius: 12px;
                    font-weight: 600;
                    line-height: 18px;
                    padding: 15px 25px;    
                }
    
            }
            @media(max-width:500px) {
                .sample_profile_text {
                    font-size: 1.2rem;
                    line-height: 30px;
                    padding: 10px 15px;
                    border-radius: 10px;
                }

                .download_sample_text {
                    font-size: 14px;
                    border-radius: 10px;
                    font-weight: 500;
                    line-height: normal;
                    padding: 10px 15px;    
                }
    
            }
            @media(max-width:400px) {
               
                .download_sample_text {
                    font-size: 8px;
                    border-radius: 10px;
                    font-weight: 500;
                    line-height: normal;
                    padding: 10px;    
                }
    
            }
        }

       
        @media(max-width:690px) {
            .left_con {
                border-radius: 20px;
                background-color: #FABBB61A;
                padding: 5%;
            }

        }

    }

    @media(max-width:1300px) {
        .section_con {
            height: auto;
            padding: 25px;
        }



    }

    @media(max-width:690px) {
        .section_con {
            height: auto;
            padding: 20px;
            border-radius: 20px;
        }

    }

    @media(max-width:520px) {
        .section_con {
            height: auto;
            padding: 15px;
        }



    }
    
}
@media(max-width:500px) {
    .adoptingParentSuccess_main_con {
        padding: 7px;
        border-radius: 15px;
    }
}