.image_section{
    display: flex;
    justify-content: center;
}

.content_section{
    margin: 2rem 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap:0.8rem;
}