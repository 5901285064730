.SubsCription_con{
    margin: 0;
    padding: 0;
    
.MAinheading{
    font-family: Lufga;
font-size: 32px;
font-weight: 600;
line-height: 42px;
letter-spacing: 0em;
text-align: center;
color: #000000;

}
.p-inputswitch-slider{
    background-color: #0592A7;
}
.textSubs{
    font-family: Lufga;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: black;
    
}
.CardCustom{
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 7px;
}
.PopularPlan{
    font-family: Inter;
font-size: 10px;
font-weight: 500;
line-height: 13px;
letter-spacing: 0em;
text-align: center;
border-radius:12px, 12px, 0px, 0px;
Padding:7px, 0px, 6px, 0px;
}
.PopularPlan2{
    background-image: linear-gradient(to right, #0592A7,#072327 );
    color: white;
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    border-radius: 8px 8px 0px 0px;
    display: block;
    padding: 7px 0px 6px 0px;
}
.bi-check2{
    background-color: #0592A7;
    padding:0px 2px ;
    border-radius: 50%;
    color: white;
}
.bi-x{
    background-color: #FFB6D9;
    padding:0px 2px ;
    border-radius: 50%;
    color: white;
} 
.PopularPlan3{
    padding: 7px 0px 6px 0px;
}
.FreeText{
    font-family: Lufga;
font-size: 20px;
font-weight: 500;
line-height: 26px;
letter-spacing: 0em;
text-align: center;
color: black;

}
.dollerText{
    font-family: Inter;
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    color: #156CFE;
    
    
}
.CompleteFreeText{
    font-family: Lufga;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;;
    color: #2D2D2D;
    text-align: center;
    
}
.SupportText{
    font-family: Lufga;
font-size: 14px;
font-weight: 500;
line-height: 16px;
letter-spacing: 0em;
text-align: center;

}
.CurrentPlantText{
    font-family: Lufga;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    padding: 7px 0px;   
    border-radius: 5px;
    color: white;
    background-image: linear-gradient(to right, #0592A7,#072327 );
    border: none;
    
}
.SelectPlantText{
    font-family: Lufga;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    padding: 7px 0px;   
    border-radius: 5px;
    color: white;
    background-color: #000000;
    border: none;
}
.BasicPlanText{ 
    font-family: Lufga;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
}
.FeaturesText{
    font-family: Lufga;
font-size: 12px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;
text-align: center;

}
.FeaturesMain{
    background-color:  #E8E8E8;
    border-radius: 3px 0px;
}
.TextICon{
    font-family: Lufga;
font-size: 12px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;
text-align: left;
color:#454545;
;

}

.borderShodowCard{
    box-shadow: 5px 5px 5px 12px #f7f5f5;
    border: 1px solid #E2E2E2;
    border-radius: 8px;
}
.MonthlyText{
    font-family: Lufga;
font-size: 18px;
font-weight: 500;
line-height: 23px;
letter-spacing: 0em;
text-align: center;
color: black;
}
.OffText {
    font-family: Inter;
font-size: 10px;
font-weight: 500;
line-height: 11px;
letter-spacing: 0em;
text-align: center;
padding: 3px 12px;
border: 1px solid black;
align-items: center;
border-radius: 56px;
display: flex;
justify-content: center;
}

}