.navLink {
    text-decoration: none;
    width: 100% !important;
}

.login-nav-item{
    font-family: Lufga;
font-size: 1rem;
font-weight: 600;
line-height: 18.27px;
text-align: left;
color: #187C8B;
padding: 0.5rem 0.8rem;
background: #f7f1f1;
border-radius: 15px;
cursor: pointer;
}

.navbarCustomClass{
    display: column !important;
}

.spanish_page {
    display: none;
    overflow-x: hidden;
    width: 100%;
}

.custom_color {
    color: #187c8b;
}

.navbar {
    font-size: 1rem;
    font-family: 'Lufga', sans-serif;
    position: sticky !important;
    top: 0 !important;

    color: black;
    font-weight: 600;
}
/* .nav-link{
    font-size: 12px !important;
} */

.navbar ul li a:hover,
.nav-link:focus {
    color: rgba(24, 124, 139, 1);
}

.nav-link:active {
    color: rgba(24, 124, 139, 1);
}

.v_header_toggle {
    width: auto;
    display: flex;
    justify-content: space-between;
}

.navbar button {
    border: none;
    border-radius: 30px;
    padding: 0.5rem 2rem;
    display: flex;
    gap: 1rem;
    background-color: transparent;
    box-shadow: 0px 4px 11px 3px rgba(100, 129, 123, 0.2);
    align-items: center;
}

.language_buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.language_buttons button {
    width: 100%;
}



.header_of_page {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2.3rem 3rem 2.3rem;
}

.image_section_of_header {
    display: flex;
    gap: 7%;
}

.list_of_header {
    display: flex;
    gap: 0.8rem;
    justify-content: center;
    align-items: center;
}

.text_header_right {
    display: flex;
    font-size: 2rem;
    text-align: left;
    font-family: 'Lufga', sans-serif;
    font-weight: 600;
    line-height: 2.5rem;
    letter-spacing: 0em;
    flex-direction: column;
    justify-content: center;
}

.text_header_right span {
    color: #187c8b;
}

.list_of_header {
    font-family: 'Lufga', sans-serif;

    font-size: 1rem;
    font-weight: 500;
    line-height: 1.56rem;
    letter-spacing: 0.015em;
    text-align: left;
}

.list_of_header div {
    cursor: pointer;
}



.navbar-collapse {
    flex-basis: unset;
    flex-grow: unset;
    justify-content: flex-end;
    align-items: center;
}

.navbar-nav a {
    text-align: center;
}


@media (max-width: 1220px) {

    .text_header_right {
        font-size: 1.8rem;
        line-height: 2.3rem;
    }

}

@media (max-width: 1070px) {

    .text_header_right {
        font-size: 1.6rem;
        line-height: 2rem;
    }

    .language_buttons button {
        padding: 5px 10px;
    }

}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
        align-items: center;
        gap: 1.2rem;

    }

    .spanish_page .navbar-expand-lg .navbar-nav {
        gap: unset;
    }

    .text_header_right {
        font-size: 2rem;
        line-height: 2.3rem;
    }
    .navbarCustomClass{
        display: column !important;
    }
}

@media (max-width: 992px) {
    .v_header_toggle {
        width: 100%;
    }
   
}

@media (max-width: 450px) {
    .navbar-collapse {
        margin: 2rem 0 0 2rem;
    }

    .image_header_left {
        width: 84px;
    }

    .image_section_of_header {
        display: flex;
        gap: 7%;
    }

    .navbar-nav a {
        text-align: left;
    }

}

.navbar {
    transition: transform 0.6s ease-in-out;
}

.hide-navbar {
    transform: translateY(-100%);
}