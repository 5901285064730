.upper_section {
    display: flex;
    background-position: center;
    height: unset;
    border-radius: 6.6rem 0px 6.6rem 0px;
    margin-top: unset;
}

.upper_section_image_couple {
    width: 45%;
}

.upper_section_content {
    width: 55%;
    background-image: unset;
}

.all_stories {
    padding-top: 4rem;
}



.any_question_section {
    /* height: 15rem; */
    background: #146497;
    margin-bottom: 8rem;
    border-radius: 20px;
    display: flex;
    justify-content: space-evenly;
    /* align-items: center; */
    position: relative;
    padding: 3rem;
}

.row_section {
    display: flex;
    justify-content: center;
}

.card_padding {
    padding: 0 9rem;
}

.left_of_any_question {
    width: 10%;
}

.left_of_any_question :nth-child(2) {
    text-align: right;
}

.right_of_any_question {
    width: 10%;
}


.right_of_any_question :nth-child(2n+1) {
    text-align: right;
}

.content_of_any_question div {
    text-align: center;
}

.input_width {
    width: 50%;
}



@media (max-width: 1000px) {
    .any_question_section {
        margin-top: 6rem;
    }

    .left_of_any_question img,
    .right_of_any_question img {
        width: 50%;
    }


    .input_width {
        width: 75%;
    }

    .all_stories .lufga_14px {
        font-size: 1.2rem;
    }

    .card_padding {
        padding: 2rem 7rem;
    }

}

@media (max-width: 800px) {
    .card_padding {
        padding: 2rem 5rem;
    }
}

@media (max-width: 450px) {
    .any_question_section {
        margin-top: 6rem;
    }

    .left_of_any_question,
    .right_of_any_question {
        display: none;
    }

    .input_width {
        width: 100% !important;
    }

    .card_padding {
        padding: 2rem 1rem;
    }
}

.DialogParent {
    width: 100%;
    font-family: 'Lufga', sans-serif;
    border-radius: 6px;

    .SignUphedaing {
        font-size: 2.25rem;
        font-weight: 700;
        color: #000000;
        font-family: 'Lufga', sans-serif;
        ;
    }


    .form-label {
        font-family: 'Lufga', sans-serif !important;
        font-size: 1rem;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.012em;
        text-align: left;
        color: #101828;

        ;



    }

   

    .Codeparent {
        position: relative;
    }

    

    .EyeView {
        position: relative;
    }

    .View {
        position: absolute;
        top: 55%;
        right: 10%;

    }

    .form-check-label {
        color: black;
        font-weight: 400;
        font-size: 1rem;
    }

    .form-control-placeholder::placeholder {
        font-size: 0.875rem;
    }

    .form-control {
        border: 1px solid rgb(177, 174, 174);
        padding: 5px 10px;
    }

    .form-select {
        border: 1px solid rgb(177, 174, 174);
    }

    .form-check-input {
        border: 1px solid rgb(177, 174, 174);
    }

    .ButtonForm {
        display: flex;
        justify-content: end;
        gap: 10px;
        margin-top: 30px;
    }

    .SaveButton {
        background-color: #187C8B;
        border-radius: 11px;
        padding: 8px 30px;
        color: white;
        font-weight: 500;
        text-align: center;
        border: none;
    }

    .CencelButton {
        background-color: transparent;
        border-radius: 11px;
        border: none;
        padding: 8px 25px;
        color: #187C8B;
        font-size: 1rem;
        font-weight: 500
    }

    .TermColor {
        color: #187C8B;
        font-size: 1rem;
        font-weight: 400;
        text-align: left;
    }

    .form-check-label {
        color: #000000;

        font-size: 1rem;
        font-weight: 400;

        text-align: left;

    }

    .TextCustomBottom {

        font-weight: 500;
        line-height: 24px;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 24px;
        color: black;



    }

    .Create {
        font-weight: 500;
        line-height: 24px;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 24px;
        color: #187C8B;

    }

    .spanaPara {
        font-weight: 500;
        line-height: 24px;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 24px;
    }

    .BackLogin {
        font-weight: 500;
        line-height: 24px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 24px;
        color: #187C8B;
    }

    .ForgetPassword {
        color: #187C8B;
        font-weight: 400;
    }

    .ButtonHowDoes{
        padding: 21px 26px;
        border-radius: 12px;
        background-color: #ba0000cd !important;
        font-family: Lufga;
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: center;
        color: white;
        border: none;
        margin-top: 20px;

    }

    @media only screen and (max-width: 640px) {


        .SignUphedaing {
            font-size: 2rem;
            font-weight: 500;
            color: #000000;
            font-family: 'Lufga', sans-serif;
            ;
        }

        .LabelHeading {
            font-family: Lufga;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.012em;
            text-align: left;
            color: #000000;

        }

    }

}