.footer .lufga_16px {
    line-height: 1.7rem;
}

.middle_lower {
    display: flex;
    justify-content: center;
    
}

.middle_lower button {
    background: white;
    border-radius: 25px;
    border: none;
    color: #187c8B;
    font-size: 1rem;
    padding:  10px 50px;
    box-shadow: 0px 3px 14px 0px rgba(77, 76, 76, 0.25) !important;
    font-weight: 600;
}
.FooterIcon{
    width: 60px !important;
    height: 60px !important;
}