  .left_img_con {
                display: flex;
                align-items: end;
                padding-bottom: 20px;
                justify-content: center;
                background-image: url("/public/images/signUpsuccessimg1.png");
                background-size: contain;
                background-position: bottom center;
                background-repeat: no-repeat;

            }