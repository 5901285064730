.Resume_con {
    margin: 10px 60px;
    padding: 20px;
    font-family: Lufga;
    border: 3px solid rgba(24, 124, 139, 1);
    border-radius: 45px;

}

.MainHeading {
    font-family: Lufga;
    font-size: 2rem;
    font-weight: 700 !important;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: 20px;
    color: #000000;
}

.p-steps-item a {
    text-decoration: none;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
    background-color: #06B217;
    color: #fff;
}
ol, ul{
    padding: 0;
}


.SubHeading {
    font-family: Lufga;
    font-size: 1.5rem;
    font-weight: 500;
    /* line-height: 48px; */
    letter-spacing: 0em;
    text-align: center;
    color: #187C8B;
}

.EyeView {
    position: relative;
}

.View {
    position: absolute;
    top: 55%;
    right: 2%;

}

.Horizontal {
    position: relative;
}

.line {
    border-top: 2px solid black;
}

.text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 0 10px;
}

.Images_Border {
    border-style: dashed;
    color: #187C8B;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 167px;

}

.bi-card-image {
    font-size: 3rem;
    color: #187C8B;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
}

.form-check-input {
    border: 1px solid rgb(177, 174, 174);
}

.form-check-label {
    color: black;
    font-weight: 400;
    font-size: 1rem;
}

.CheckBox_parent {
    display: flex;
    margin-top: 8px;
    justify-content: space-between;

}

.CheckBoxParent2 {
    display: flex;
    margin-top: 8px;
    gap: 40px;
}

.CheckBoxParent3 {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
    margin-bottom: 25px;
}

.CheckBoxParent4 {
    display: flex;
    gap: 290px;
}

.ChecBoxLabel {
    font-family: Lufga;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;

}

.form-label {
    font-family: Lufga;
}

.form-check-label {
    font-family: Lufga;
}

.ButtonForm {
    display: flex;
    justify-content: end;
    gap: 10px;
    margin-top: 30px;
}

.SaveButton {
    background-color: #187C8B;
    border-radius: 11px;
    padding: 8px 30px;
    color: white;
    font-weight: 500;
    text-align: center;
    border: none;
}

.card:hover {
    background-color: none;
}

.Previous {
    font-family: Lufga;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    border: none;
    color: #187C8B;
    background-color: white;

}





.ParaSub {
    font-family: Lufga;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: center;

}

.InputMiddleHaeding {
    font-family: Lufga;
    font-size: 20px;
    font-weight: 500;
    /* line-height: 48px; */
    letter-spacing: 0em;
    text-align: left;
    color: black;

}



@media screen and (max-width:600px){
    .Resume_con {
        margin: 10px 15px;
        padding: 10px;
        font-family: Lufga;
        border: 2px solid rgba(24, 124, 139, 1);
    }
}