.Congrats_con{

}
#pr_id_93_content{
    padding: 0px !important;
    margin: 0px !important;
}
.CongratsImage{
    background-image: url('/public/images/Frame\ 1171274974.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px;
    /* border: 3px solid #187C8B; */
}
.p-dialog-content{
    
}

.CongratulationsText{
    font-family: Lufga;
font-size: 32px;
font-weight: 600;
line-height: 48px;
letter-spacing: 0em;
text-align: center;
color: black;
}
.CongratsImage2{
    display: flex;
    justify-content: center;
    align-items: center;
}
.ExpolreButton{
    font-family: Lufga;
font-size: 18px;
font-weight: 500;
line-height: 23px;
letter-spacing: 0em;
text-align: center;
background-color: #187C8B;
border-radius: 12px;
padding:15px 30px ;
border: none;
color: white;

}