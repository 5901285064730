
body {
    font-family: 'Lugfa', sans-serif;
} 

.main_div {
    display: block;
    overflow-x: hidden;
    width: 100%;
}



.chienese_page {
    display: none;
    overflow-x: hidden;
    width: 100%;
}



.upper_section {
    display: flex;
    background-position: center;
    /* height: 100vh; */
}

.upper_section_image_couple {
    width: 55%;
    /* border: 1px solid red; */
}

.upper_section_image_couple img {
    width: 100%;
    /* height: 90%; */
}

.upper_section_content {
    padding: 0 0 0 6%;
    width: 45%;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    background-image: radial-gradient(circle at -40% center, rgba(213, 174, 228, 1) 0%, white 50%);
    /* border: 1px solid red; */
}



.lufga_20px_bg_lightblue {
    font-family: 'Lufga', sans-serif;

    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.62rem;
    letter-spacing: 0em;
    text-align: left;
    color: #177D8B;


}

.volkhov_48px {
    font-family: Volkhov;
    font-size: 3rem;
    font-weight: 700;
    line-height: 4.5rem;
    letter-spacing: -0.04em;
    text-align: left;

}

.lufga_18px_bg_gray {
    font-family: 'Lufga', sans-serif;

    font-size: 1.125rem;
    font-weight: 400;
    line-height: 2.125rem;
    letter-spacing: 0em;
    text-align: left;
    color: #5E6282;
}

.lufga_14px {
    font-family: 'Lufga', sans-serif;

    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.5px;
    text-align: left;
}

.lufga_16px_bg_gray {
    font-family: 'Lufga', sans-serif;

    font-size: 1rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.5px;
    text-align: left;
    color: #5E6282;
}

.lufga_16px {
    font-family: 'Lufga', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.5px;
    text-align: left;
}

.lufga_18px {
    font-family: 'Lufga', sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 2.25rem;
    letter-spacing: 0em;
    text-align: left;
}


.lufga_24px {
    font-family: 'Lufga', sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2.25rem;
    letter-spacing: 0em;
    text-align: left;
}

.navLink{
    text-decoration: none;
    border-bottom: none;
}


.lufga_20px {
    font-family: 'Lufga', sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    letter-spacing: 0em;
    text-align: left;

}

.lufga_20px_bg_gray {
    font-family: 'Lufga', sans-serif;

    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(99, 99, 99, 1);

}

.lufga_36px {
    font-family: 'Lufga', sans-serif;

    font-size: 2.25rem;
    font-weight: 600;
    line-height: 3.125rem;
    letter-spacing: 0em;
    text-align: left;

}

.quicksand_17px {
    font-family: Quicksand;
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
}


.button_section_for_upper_section {
    display: flex;
    gap: 0.2rem;
    margin-top: 1rem;
}

.button_section_for_upper_section :nth-child(1) {
    background: #187c8B;
    border: none;
    border-radius: 11.36px;
    color: white;
    padding: 0.8rem 1.6rem;
    font-family: 'Lufga', sans-serif;



}

.button_section_for_upper_section :nth-child(2) {
    background: transparent;
    color: black;
    border: none;
    border-radius: 11.36px;
    padding: 0.8rem 1.6rem;
    font-family: 'Lufga', sans-serif;

}

.all_stories {
    padding: 0 4.875rem 6.875rem 4.875rem;
}


.card {
    border: none;
    width: 100% !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 14.02px 14.02px 21.03px 14.02px;
    border-radius: 14.02px;
    gap: 21.03px;
    box-shadow: 0px 3.5054876804351807px 14.021950721740723px 0px rgba(158, 158, 158, 0.25);
}

.card-img-top {
    border-radius: 16px;
}

.body_of_card {
    display: flex;
}

.card-body {
    padding: 0;
}

.card:hover {
    background: rgba(24, 124, 139, 1) !important;
    cursor: pointer;
    color: white;
}


.all_stories a{
    display: inline-block;
    text-decoration: none;
    width: 100%;
    height: 100%;
}




.choose_us_section {
    padding-left: 4rem;
    display: flex;
    padding-bottom: 6rem;

}

.text_section_of_choose {
    width: 50%;
    padding: 1rem 4rem 1rem 0;
    /* border: 2px solid green; */
}

.text_content_of_choose {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}

.text_content_of_choose>div {
    cursor: pointer;
}

.text_content_of_choose>div:nth-child(1) {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.text_content_of_choose>div:nth-child(2) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


.text_content_of_choose>div:nth-child(2)>div {

    padding: 8px 24px 8px 24px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.text_content_of_choose>div:nth-child(2)>div:hover {
    box-shadow: 0px 16px 24px 0px rgba(96, 97, 112, 0.12), 0px 2px 8px 0px rgba(40, 41, 61, 0.02);

}

.great_sign {
    font-weight: 600;
    display: inline-flex;
    transform: translate(3px, 2px);
    cursor: pointer;
}



.image_section_of_choose {
    width: 50%;
    /* border: 2px solid red; */
}

.image_section_of_choose img {
    margin-top: 83px;
    height: 80%;
    width: 100%;
}

.footer {
    display: flex;

}

.left_footer {
    /* background: url("https://eduma.io/wp-content/uploads/2024/02/image_2024_02_09T10_54_13_894Z.png"); */
        background: url(../../../../public/images/footerRight.png);
        background-size: 100% 100%;
        background-position: center;
        width: 60%;
        padding: 4%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        /* gap:2rem; */
    }
    
    .right_footer {
        /* background: url("https://eduma.io/wp-content/uploads/2024/02/image_2024_02_09T10_54_25_293Z.png"); */
        background: url(../../../../public/images/footerleft.png);
    
        background-size: 100% 100%;
        background-position: center;
        width: 40%;
        padding: 10% 5% 5% 5%;
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        gap: 2rem;
    }


.left_lower_footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
}


.left_right_section_footer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.left_right_section_heading_footer {
    font-family: 'Lufga', sans-serif;

    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
}

.left_right_section_content_footer {
    font-family: 'Lufga', sans-serif;

    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
}


.right_footer div,
.right_footer a {
    color: white;
}

.right_upper,
.right_middle,
.right_lower {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
}

.middle_upper input {
    width: 100%;
    border-radius: 6px;

    padding: 10px 0;
    padding-left: 16px;

    box-shadow: none;
    border: none;
}



.lower_lower {
    display: flex;
    gap: 0.7rem;
}

.lower_lower a img {
    width: 100%;
}

.lower_lower img {
    width: 12%;
    cursor: pointer;
}

.lower_upper {
    font-size: 1.5rem;
}

.no_style {
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

/* 
.spanish_page .text_header_right {
    font-size: 1.5rem;
} */

@media (max-width:1000px) {
    :root {
        font-size: 14px;
    }

    .upper_section {
        flex-direction: column-reverse;
        /* margin-top: 30rem; */
    }

    .upper_section>div {
        width: 100%;
    }


    .footer {
        flex-direction: column;
    }

    .footer>div {
        width: 100%;
    }

    .left_lower_footer div {
        font-size: 1rem !important;
    }

    .left_lower_footer {
        margin-top: 4rem;
    }

    .choose_us_section {
        flex-direction: column-reverse;
    }

    .text_section_of_choose,
    .image_section_of_choose {
        width: 100%;
    }

    .left_upper_footer {
        margin-top: 2rem;
    }
}

@media (max-width: 800px) {
    .upper_section_content {
        background: transparent !important;
        margin-bottom: 7rem;

    }

    .all_stories {
        padding: 0 5%;
    }

    /* .upper_section {
        margin-top: 25rem;
    } */
}

@media (max-width: 450px) {
    :root {
        font-size: 12px;
    }

    /* .navbar-nav {
        font-size: 1.5rem;
    } */

    .nav_list {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .upper_image_section {
        flex-direction: column-reverse;
        padding: 4rem 0;
        gap: 5rem;
    }

    .img_of_upper_section {
        display: flex;
        justify-content: center;
    }

    .img_of_upper_section img {
        width: 50%;
    }

    /* .upper_section {
        margin-top: 7rem;
    } */

    .spanish_page .upper_section{
        /* margin-top: 22rem; */
    }

    .card-body .lufga_18px {
        font-size: 1.135rem !;
    }

    .middle_upper input {
        padding: 12px;
        padding-left: 1rem;
        font-size: 2rem;
    }

    .middle_upper input::placeholder {
        font-size: 2rem;
        padding-left: 1rem;
        padding-top: 1rem;
    }

    .choose_us_section .lufga_16px_bg_gray {
        font-size: 1.25rem;
        line-height: 1.8rem;
    }

    .choose_us_section .lufga_20px {
        font-size: 1.55rem;
        line-height: 2rem;
    }

    

    .middle_lower button {
        margin-top: 1rem;
        font-size: 2rem;
    }

    .image_section_of_header {
        display: flex;
        /* gap: 0.8rem; */
    }

    .navbar-collapse {
        margin: 2rem 0 0 2rem;
    }

}

@media (max-width: 361px) {
    :root {
        font-size: 8px;
    }

    
}