.DialogParent{
   
    .LockImages{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    
    .ViewSubscription{
        font-family: Lufga;
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: center;    
    background-color: #187C8B !important;
    padding: 21px 26px;
    border: none;
    border-radius: 12px;
    color: white;
    }
    .LockText{
        font-family: Lufga;
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: center;
    color: black;
    }
    
}