.upper_section {
    display: flex;
    background-position: center;
    height: unset;
    border-radius: 6.6rem 0px 6.6rem 0px;
    margin-top: unset;
}

.upper_section_image_couple {
    width: 45%;
}

.upper_section_content {
    width: 55%;
    background-image: unset;
}

.all_stories {
    padding-top: 4rem;
}

.phone_verified_text {
    position: absolute;
    top: 7px;
    right: 10px;
    color: #187C8B
}

.any_question_section {
    /* height: 15rem; */
    background: #146497;
    margin-bottom: 8rem;
    border-radius: 20px;
    display: flex;
    justify-content: space-evenly;
    /* align-items: center; */
    position: relative;
    padding: 3rem;
}

.row_section {
    display: flex;
    justify-content: center;
}

.card_padding {
    padding: 0 7rem;
}

.left_of_any_question {
    width: 10%;
}

.left_of_any_question :nth-child(2) {
    text-align: right;
}

.right_of_any_question {
    width: 10%;
}


.right_of_any_question :nth-child(2n+1) {
    text-align: right;
}

.content_of_any_question div {
    text-align: center;
}

.input_width {
    width: 50%;
}
@media (max-width: 1000px) {
    .any_question_section {
        margin-top: 6rem;
    }

    .left_of_any_question img,
    .right_of_any_question img {
        width: 50%;
    }


    .input_width {
        width: 75%;
    }

    .all_stories .lufga_14px {
        font-size: 1.2rem;
    }

    .card_padding {
        padding: 2rem 7rem;
    }

}

@media (max-width: 800px) {
    .card_padding {
        padding: 2rem 5rem;
    }
}

@media (max-width: 450px) {
    .any_question_section {
        margin-top: 6rem;
    }

    .left_of_any_question,
    .right_of_any_question {
        display: none;
    }

    .input_width {
        width: 100% !important;
    }

    .card_padding {
        padding: 2rem 1rem;
    }
}



.DialogParent {
    width: 100%;
    font-family: 'Lufga', sans-serif;
    border-radius: 6px;

    .SignUphedaing {
        font-size: 2.25rem;
        font-weight: 700;
        color: #000000;
        font-family: 'Lufga', sans-serif;
        ;
    }


    .completeIcon {
        color: rgba(24, 124, 139, 1) !important;
        /* font-size: 1.8rem; */
    }


    .form-label {
        font-family: 'Lufga', sans-serif !important;
        font-size: 1rem;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.012em;
        text-align: left;
        color: #101828;

        ;



    }



    .GetCode {
        position: absolute;
        top: 6px;
        right: 10px;
        color: #187C8B;
        font-weight: 500;
        font-size: 1rem;
        z-index: 2;
        background: white;
    }

    .GetCodeEmail {
        position: absolute;
        top: 6px;
        right: 10px;
        color: #187C8B;
        font-weight: 500;
        font-size: 1rem;
        z-index: 2;
    }

    .EyeView {
        position: relative;
    }

    .View {
        position: absolute;
        top: 5px;
        right: 10px;
    }

    .form-check-label {
        color: black;
        font-weight: 400;
        font-size: 1rem;
    }

    .form-control-placeholder::placeholder {
        font-size: 0.875rem;
    }

    .form-control {
        border: 1px solid rgb(177, 174, 174);
        padding: 5px 10px;
    }

    .form-select {
        border: 1px solid rgb(177, 174, 174);
    }

    .form-check-input {
        border: 1px solid rgb(177, 174, 174);
    }

    .ButtonForm {
        display: flex;
        justify-content: end;
        gap: 10px;
        margin-top: 30px;
    }

    .SaveButton {
        background-color: #187C8B;
        border-radius: 11px;
        padding: 20px 30px;
        color: white;
        font-weight: 500;
        text-align: center;
        border: none;
    }

    .CencelButton {
        background-color: transparent;
        border-radius: 11px;
        border: none;
        padding: 8px 25px;
        color: #187C8B;
        font-size: 1rem;
        font-weight: 500
    }

    .TermColor {
        color: #187C8B;
        font-size: 1rem;
        font-weight: 400;
        text-align: left;
    }

    .form-check-label {
        color: #000000;

        font-size: 1rem;
        font-weight: 400;

        text-align: left;

    }

    .TextCustomBottom {

        font-weight: 500;
        line-height: 24px;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 24px;
        color: black;



    }

    .Create {
        font-weight: 500;
        line-height: 24px;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 24px;
        color: #187C8B;

    }

    .spanaPara {
        font-weight: 500;
        line-height: 24px;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 24px;
    }

    .BackLogin {
        font-weight: 500;
        line-height: 24px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 24px;
        color: #187C8B;
    }

    .ForgetPassword {
        color: #187C8B;
        font-weight: 400;
    }

    .ButtonHowDoes {
        padding: 21px 26px;
        border-radius: 12px;
        background-color: #ba0000cd !important;
        font-family: Lufga;
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: center;
        color: white;
        border: none;
        margin-top: 20px;

    }

    @media only screen and (max-width: 640px) {



        .SignUphedaing {
            font-size: 2rem;
            font-weight: 500;
            color: #000000;
            font-family: 'Lufga', sans-serif;
            ;
        }

        .LabelHeading {
            font-family: Lufga;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.012em;
            text-align: left;
            color: #000000;

        }

    }



}

.LoaderCustom {
    background-color: unset !important;
    box-shadow: unset !important;
}

/* .p-dialog {
    background: transparent!;
    .p-dialog-content{
        background: transparent!;
    }
} */


.surrogateSuccess_main_con {
    font-family: Lufga;
    padding: 10px;
    border-radius: 25px;
    background: linear-gradient(to bottom right, #AEF2EF, #ffffff, #ffffff, #ffffff, #AEF2EF);
    /* margin: 10px; */

    .section_con {
        padding: 30px;
        background-image: url("/public/images/signUpsuccessImg.png");
        /* background-size: 100; */
        background-size: contain;
        background-position: right center;
        background-color: #f6f6f6 !important;
        height: 350px;
        background-repeat: no-repeat;


        border-radius: 25px;
        background-color: white;

        .success_line_2 {
            font-size: 2rem;
            font-weight: 600;
            line-height: 56.47px;
            text-align: left;

        }

        .primary_text {
            font-size: 2rem;
            font-weight: 600;
            color: #187C8B;
        }

        @media(max-width:1300px) {
            .success_line_2 {
                font-size: 1.5rem;
                font-weight: 600;
                line-height: 30px;
                text-align: left;

            }

            .primary_text {
                font-size: 1.5rem;
                font-weight: 600;
                color: #187C8B;
                /* margin-top: 5%; */
            }

        }

        @media(max-width:690px) {
            .success_line_2 {
                font-size: 1.2rem;
                font-weight: 600;
                line-height: normal;
                text-align: left;

            }

            .primary_text {
                font-size: 1.2rem;
                font-weight: 600;
                color: #187C8B;
                /* margin-top: 5%; */
            }

        }

        @media(max-width:500px) {
            .success_line_2 {
                font-size: 1rem;
                font-weight: 600;
                line-height: normal;
                text-align: left;

            }

            .primary_text {
                font-size: 1rem;
                font-weight: 600;
                color: #187C8B;
                /* margin-top: 5%; */
            }

        }
    }



    .section_two {
        display: grid;
        margin-top: 20px;
        grid-template-columns: 48% 48%;
        justify-content: space-between;
        /* gap: 30px; */


        .left_con {
            border-radius: 40px;
            background-color: #FABBB61A;
            padding: 5%;

            .text_1 {
                font-family: Lufga;
                font-size: 2.5rem;
                font-weight: 600;
                line-height: 56.47px;
                text-align: left;

            }

            .text_2 {

                font-family: Lufga;
                font-size: 1.563rem;
                font-weight: 500;
                line-height: 56.47px;
                text-align: left;

            }


            .left_img_con {
                display: flex;
                align-items: end;
                padding-bottom: 20px;
                justify-content: center;
                background-image: url("/public/images/signUpsuccessimg1.png");
                background-size: contain;
                background-position: bottom center;
                background-repeat: no-repeat;

            }

            @media(max-width:1400px) {
                .text_1 {
                    font-size: 2rem;
                    line-height: 45px;
                }

                .text_2 {
                    font-size: 1.563rem;
                    line-height: 45px;
                }

            }

            @media(max-width:1300px) {
                .text_1 {
                    font-size: 1.5rem;
                    line-height: 40px;
                }

                .text_2 {
                    font-size: 1.4rem;
                    line-height: 40px;
                }

            }

            @media(max-width:900px) {
                .text_1 {
                    font-size: 1.4rem;
                    line-height: 30px;
                }

                .text_2 {
                    line-height: 30px;
                    font-size: 1.3rem;
                }

            }

            @media(max-width:500px) {
                .text_1 {
                    font-size: 1.1rem;
                    line-height: normal;
                }

                .text_2 {
                    line-height: normal;
                    font-size: 1rem;
                }

            }




        }



        .right_con {
            .sample_profile_text {
                font-family: Lufga;
                font-size: 2rem;
                font-weight: 500;
                line-height: 61.55px;
                color: #187C8B;
                border-radius: 20px;
                padding: 15px 30px;
                background-color: #e8f2f3;
            }

            .download_sample_text {
                background-color: #F5F6FA;
                font-family: Lufga;
                font-size: 18px;
                border-radius: 20px;
                font-weight: 600;
                line-height: 23.49px;
                text-align: center;
                padding: 15px 30px;
                color: black;
                display: flex;
                align-items: center;
                justify-content: center;

            }
            @media(max-width:1300px) {
                .sample_profile_text {
                    font-size: 1.5rem;
                    line-height: 50px;
                    padding: 15px 30px;
                }
            }
            @media(max-width:900px) {
                .sample_profile_text {
                    font-size: 1.5rem;
                    line-height: 40px;
                    padding: 10px 20px;
                    border-radius: 15px;
                }

                .download_sample_text {
                    font-size: 17px;
                    border-radius: 12px;
                    font-weight: 600;
                    line-height: 18px;
                    padding: 15px 25px;    
                }
    
            }
            @media(max-width:500px) {
                .sample_profile_text {
                    font-size: 1.2rem;
                    line-height: 30px;
                    padding: 10px 15px;
                    border-radius: 10px;
                }

                .download_sample_text {
                    font-size: 14px;
                    border-radius: 10px;
                    font-weight: 500;
                    line-height: normal;
                    padding: 10px 15px;    
                }
    
            }
            @media(max-width:400px) {
               
                .download_sample_text {
                    font-size: 8px;
                    border-radius: 10px;
                    font-weight: 500;
                    line-height: normal;
                    padding: 10px;    
                }
    
            }
        }

       
        @media(max-width:690px) {
            .left_con {
                border-radius: 20px;
                background-color: #FABBB61A;
                padding: 5%;
            }

        }

    }

    @media(max-width:1300px) {
        .section_con {
            height: auto;
            padding: 25px;
        }



    }

    @media(max-width:690px) {
        .section_con {
            height: auto;
            padding: 20px;
            border-radius: 20px;
        }

    }

    @media(max-width:520px) {
        .section_con {
            height: auto;
            padding: 15px;
        }



    }
    
}
@media(max-width:500px) {
    .surrogateSuccess_main_con {
        padding: 7px;
        border-radius: 15px;
    }
}