.banner {
  margin: 5rem;
  position: relative;
}
.banner_abolute{
  position: absolute;
  top: 30%;
  width: 50%;
  left: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* transform: translate(-50%,50%); */
}

.lufga_18px_style{
  font-family: 'Lufga', sans-serif;

  font-size: 1.125rem;
  font-weight: 400;
  line-height: 2.125rem;
  letter-spacing: 0em;
  text-align: center;
  color: #5E6282;
}
.lufga_36px_style{
  font-family: 'Lufga', sans-serif;

  font-size: 2.25rem;
  font-weight: 600;
  line-height: 3.125rem;
  letter-spacing: 0em;
  text-align: left;
}

.main_content {
  display: flex;
  margin-bottom: 5rem;
}

.main_img {
  margin-right: 20px;
  width: 45%;
}

.main_img_tag {
  width: 100%;
  height: 100%;
}
.right_main_container{
background-color: #e6f3f2;
width: 55%;
align-items: center;
}

.right_container {
  /* background-color: #e6f3f2;
  width: 55%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0 0 0 4rem;
}
.free_consultant_request{

}

.right_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70%;
}

.link_section {
  display: flex;
  justify-content: space-between !important;
}

.link_section img {
  width: 20%;
}

.input_form {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0px 0px 3rem 0rem;
}

.input_form_main {
  width: 50%;
  margin: 5rem;
}

.label_input {
  color: #00024b80;
  font-size: 1.2rem;
}

.input_form input {
  width: 100%;
  background: transparent;
  border: none;
  border-bottom: 1px solid rgb(0, 0, 0);
}

.input_form input:focus {
  outline: none;
  border-bottom: 1px solid rgb(0, 0, 0) !important;
  border-bottom-color: transparent;
}

.btn {
  background-color: #187c8b;
  border-radius: 40px;
  width: 100%;
  padding: 1rem 1rem;
  color: white;
  font-weight: 500;
  text-align: center;
  border: none;
}



.right_one {
  width: 50%;
}

@media screen and (max-width: 398px) {
  .main_content {
    display: flex;
    flex-direction: column;
  }
  .btn {
    background-color: #187c8b;
    border-radius: 40px;
    width: 100%;
    padding: 5px 5px;
    color: white;
    text-align: center;
    border: none;
  }
  .input_form_main{
    width: 60%;
    margin: 5rem;
  }
  .right_inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70%;
  }
  .right_div{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .right_one {
    width: 70%;
    margin-bottom: 2rem;
  }
  .link_section {
    display: flex;
    justify-content: space-around !important;
  }
  
  .link_section img {
    width: 13%;
  }
}

@media (max-width : 430px) {
  .main_content {
    display: flex;
    flex-direction: column;
  }
  .main_img{
    width: 98%;
  }
  .right_main_container{
    width:100%;
    background-color: #e6f3f2;
   }
  /* .right_container{
    width: 100%;
  } */

  .banner{
    margin: 2rem;
  }
  .banner_abolute{
    top: 10%;
  }
  .lufga_18px_style{
    font-size: 0.8rem;
    line-height: 1rem;
  }
  .lufga_36px_style{
    font-size: 1.5rem;
    line-height: 2.5rem;
  }
  .input_form_main{
    width: 60%;
    margin: 5rem;
  }
  .right_inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70%;
  }
  .right_div{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .right_one {
    width: 70%;
    margin-bottom: 2rem;
  }
  .link_section {
    display: flex;
    justify-content: space-around !important;
  }
  
  .link_section img {
    width: 13%;
  }

}

@media (min-width: 431px) and (max-width : 576px) {
  .main_content {
    display: flex;
    flex-direction: column;
  }
  .main_img{
    width: 98%;
  }
  .right_main_container{
    width:100%;
    background-color: #e6f3f2;
   }
  /* .right_container{
    width: 100%;
  } */
  .banner{
    margin: 2rem;
  }
  .banner_abolute{
    top: 12%;
  }
  .lufga_18px_style{
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .lufga_36px_style{
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
  .input_form_main{
    width: 60%;
    margin: 5rem;
  }
  .right_inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70%;
  }
  .right_div{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .right_one {
    width: 70%;
    margin-bottom: 2rem;
  }
  .link_section {
    display: flex;
    justify-content: space-around !important;
  }
  
  .link_section img {
    width: 13%;
  }
}

@media (min-width : 576.87px) and (max-width: 768px) {
  .main_content {
    display: flex;
    flex-direction: column;
  }
  .banner_abolute{
    top: 10%;
  }

  .main_img {
    width: 95%;
    margin-left: 10px;
  }
  .right_main_container{
    width:100%;
    background-color: #e6f3f2;
   }

  /* .right_container {
    background-color: #e6f3f2;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  } */

  .right_one {
    padding: 5px;
  }
}
