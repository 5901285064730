.vv_2{
    background: #E6F3F2;
    padding:4rem;
    display: flex;
    gap:2rem;
    justify-content: center;
}


.v_2_11{
    width: 70%;
    display: flex;
    flex-direction: column;
    gap:1.2rem;
    justify-content: center;
    align-items: center  ;
}
/* 
.upper_section_content{

    background: url("/public/images/relationship.png"); 
} */

c
.text_space{
    font-weight: 600 !important;
    
}

.text_spacee{
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    color: #252641;
}

.text_spacep{
    font-weight: 400;
    line-height: 33px;
    color: #696984;
    font-size: 18px;

}
.back_img{
    background-image: url("/public/images/relationship.png");
    background-repeat:no-repeat; 
    background-size: cover;
    height: 417px;
    background-position: center;
    padding: 0;
    display: flex;
    justify-content:center;
    border-radius: 15px;
    align-items: end;
    background-size: cover;
   
   

}
.back_col{
    background: rgba(0, 0, 0, 0.521);
    border-radius: 10px;
    padding: 18px;
    width: 100%;
    color: #ffff;
}


@media only screen and (max-width: 1000px) {
    .vv_2{
        background: #E6F3F2;
     
        flex-direction: column !important;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .v_2_11{
        justify-content: center;
        width: 100%;

    }
    .vv_2 img{
        width: 85% !important;
    }



  }

  @media only screen and (max-width: 980px) {
    .vitro{
        margin-top: 10px;
    }
  }



