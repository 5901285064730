.multipleImageInputLabel {
    border: 2px dashed #187C8B;
    padding: 20px;
    min-height: 200px;
    border-radius: 15px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;

    .img_con {
        width: 100px;
        height: 100px;
        border-radius: 10px;
        overflow: hidden;
        position: relative;

        .img_cross_icon {
            font-size: 25px;
            color: red;
            position: absolute;
            top: 10px;
            right: 10px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
        }
    }

    .input_box_design {
        display: flex;
        width: 100px;
        border-radius: 10px;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        height: 100px;
        color: #187C8B;
        border: 2px dashed #187C8B;
    }

    .input_img_icon {
        font-size: 40px;
        color: #187C8B;
    }
}