.card_wrapper a{
    text-decoration: none;
    color:unset;
}

.card{
    border:1px solid #E8E8EA;
}

.card:hover{
    background:unset !important;
    color:unset;
}