.main_div{
    width: 100%;
    margin: 3% 0;
    box-sizing: border-box;
}

.partition_container{
    display: flex;
    justify-content: space-between;
    padding: 3% 0;
}

.partition_container_left{
    width: 44%;
    /* height: 90%; */
    display: flex;
    align-items: center;
}


.partition_container_left img{
    width: 100%;
}


.partition_container_right{
    width: 54%;
    background: #E6F3F2;
    border-radius: 0 0 0 64px;
    padding:4%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rightContainerInner{
    display: flex;
    justify-content: space-between;
}

.left_one{
    width: 50%;
 
}


.label_input{
    color:#00024B80;
}

.banner{
    width: 100%;
    padding: 3% 5%;
}


.left_one input[type=text] {
width: 100%;
padding: 3px 10px;
background: transparent;
margin: 8px 0;
box-sizing: border-box;
border: none;
border-bottom: 2px solid rgb(0, 0, 0);
}

.link_section{
    display: flex;
    justify-content: space-between;
}

.button_submit{
    width: 314.93px;
height: 65.99px;
top: 1105.08px;
left: 733px;
padding: 20.49px, 102.47px, 20.49px, 102.47px;
border-radius: 40.99px;
gap: 8.54px;
background:#187C8B;
color:white;
border:none;
}

@media(max-width: 900px) {
    .partition_container{
        display: flex;
        flex-direction: column;
    }
    .partition_container_left{
        width: 100%;
        /* margin-bottom: 3rem; */
    }

    .partition_container_right{
        width: 100%;
    }
  
}

@media(max-width: 450px) {
    .partition_container{
        display: flex;
        flex-direction: column;
    }
    .partition_container_left{
        width: 100%;
    }

    .partition_container_right{
        width: 100%;
    }
    .left_one{
        width: 100%;
        padding:4rem 0;

    }
    .right_one{
        display: flex;
        justify-content: space-around;
    }
    .link_section{
        flex-direction: column;
        height: 100%;
        margin-top: 0 !important;
        padding-top: 0 !important;
        align-items: flex-end;
    }
    .button_submit{
        width: 100%;
    }

    .rightContainerInner{
        flex-direction: column-reverse;
    }
  
}