.IntendedParents_con{
   
overflow: hidden;
    .Mainheading{
        font-family: Lufga;
font-size: 48px;
font-weight: 500;
line-height: 48px;
letter-spacing: 0em;
text-align: center;
color: black;

    }
    .ImagesBackground{
        display: flex;
        justify-content: center;
        align-items: center;
    }

.SurrogateColor{
    color: #187C8B;
    font-family: Lufga;
font-size: 48px;
font-weight: 500;
line-height: 48px;
letter-spacing: 0em;
text-align: center;

}
.ImagesMain{
  display: flex;
  justify-content: center;
  align-items: center;
  

}
.textcustom{
 align-items: center;
 display: flex;

}
.ImagesComun{
    display: flex;
 
    gap: 30px;
}

.ImagesCustom2{
   
   z-index: -1;
}

.BulidText{
    font-family: Lufga;
font-size: 24px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;

align-items: left;
color: black;
}
.BoxColor{
    background-color: #E6F3F2;
    padding:  20px 0px;
    margin-bottom: 30px;
}
.BoxColor2{
    background-color: #FFEAE9;
    padding:  20px 0px;
   
}
.backGroungImages{
    background-image: url(/public/images/image_2024_03_01T06_05_00_162Z.png);
    width: 100%;
    height: 100Vh;
    background-repeat: no-repeat;
    background-size: cover;
   /* Adjust the opacity value to darken or lighten */
}

.boxContent{
    
    position: relative;
}
.BoxColortwo{
    padding:  20px 0px;

    width: 100%;
    height: 100%;
    background-color: hsla(0, 0%, 0%, 0.692);
    translate: transform(100%, 0);
    /* position: absolute; */
 
    /* margin-bottom: 30px; */
}
.Modaldiv{
    background-color: rgb(255, 255, 255);
    position: absolute;
    top:30%;
    margin-left: 15%;
    width: 70%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    box-shadow: #AEF2EF
    ;
}
.SubsCriptiontext{
    font-family: Lufga;
font-size: 32px;
font-weight: 500;
line-height: 42px;
letter-spacing: 0em;
text-align: center;
display: flex;
justify-content: center;

}
.LockImages{
    display: flex;
    justify-content: center;
}

.ViewSubscription{
    background-color: #187C8B;
    padding: 21px 26px;
    border: none;
    font-size: 20px;
    font-family: Lufga;
    font-weight: 500;
    border-radius: 12px;
    color: white;
    
}
}