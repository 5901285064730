.ResumeStep_con {
    margin: 10px 30px;
    padding: 10px;
    font-family: Lufga;

    .MainHeading {
        font-family: Lufga;
        font-size: 2.25rem;
        font-weight: 500;
        line-height: 48px;
        letter-spacing: 0em;
        text-align: center;
        color: #000000;
    }

    .SubHeading {
        font-family: Lufga;
        font-size: 2rem;
        font-weight: 600;
        /* line-height: 48px; */
        letter-spacing: 0em;
        text-align: center;
        color: #187C8B;
        /* margin: 30px 0px; */
    }

    .LabelHeading {
        font-family: Lufga;
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.012em;
        text-align: left;
        color: #000000;

    }

    .MiddleSubHeading {
        font-family: Lufga;
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;

    }

    .EyeView {
        position: relative;
    }

    .View {
        position: absolute;
        top: 55%;
        right: 2%;

    }

    .Horizontal {
        position: relative;
    }

    .line {
        border-top: 2px solid black;
    }

    .text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 0 10px;
    }

    .Images_Border {
        border-style: dashed;
        color: #187C8B;
        padding-top: 50px;
        border-radius: 10px;
        padding-bottom: 50px;
        display: flex;
        justify-content: center !important;

    }

    .center-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: center; */
    }

    .UploadName {
        text-align: center;
        color: #000000;
        font-family: Lufga;
        font-size: 1rem;
        font-weight: 400;


    }

    .imagesCustom {
        font-size: 3rem;
        color: #187C8B;
        text-align: center;
        align-items: center;
        cursor: pointer;
        display: flex;
        justify-content: center;


    }

    .form-check-input {
        border: 1px solid rgb(177, 174, 174);
    }

    .CheckBox_parent {
        display: flex;
        margin-top: 8px;
        justify-content: space-between;

    }

    .CheckBoxParent2 {
        display: flex;
        margin-top: 8px;
        gap: 40px;
    }

    .CheckBoxParent3 {
        display: flex;
        margin-top: 30px;
        justify-content: space-between;
        margin-bottom: 25px;
    }

    .CheckBoxParent4 {
        display: flex;
        gap: 290px;
    }


    .ButtonForm {
        display: flex;
        justify-content: end;
        gap: 10px;
        margin-top: 30px;
    }

    .SaveButton {
        background-color: #187C8B;
        border-radius: 11px;
        padding: 8px 30px;
        color: white;
        font-weight: 500;
        text-align: center;
        border: none;
    }

    .card:hover {
        background-color: none;
    }

    .Previous {
        font-family: Lufga;
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
        border: none;
        color: #187C8B;
        background-color: white;

    }



    .LabelSubHeading {
        font-family: Lufga;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #101828;
    }

    .ParaSub {
        font-family: Lufga;
        font-size: 1rem;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.012em;
        text-align: center;

        color: #000000;


    }

    .InputMiddleHaeding {
        font-family: Lufga;
        font-size: 1.25rem;
        font-weight: 600 !important;
        /* line-height: 48px; */
        letter-spacing: 0em;
        text-align: left;
        color: black;

    }

    .imageSize {
        height: 100px;
        width: 100px;
        display: flex;
        gap: 10px;
        object-fit: cover;
        position: relative;
    }

    .pointer12 {
        box-sizing: content-box;
        right: 5px;
        top: 5px;
        position: absolute;
        z-index: 2;
        border-radius: 50%;
        padding: 2px;
        color: red;
        background: white;
        cursor: pointer;

    }

    .ImgesandIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .add_text_css {
        font-size: 1.2rem !important;
    }


    .hidetext1{
        display: none;
    }
    .hidetext2{
        display: block;
    }

    @media screen and (max-width: 767px) {
        .hidetext1{
            display: block;
        }
        .hidetext2{
            display: none;
        }
      }



}

@media screen and (max-width:860px){
    .imageSize {
        width: 60px !important;
        height: 60px !important;
        object-fit: cover;
        position: relative;
    }
}
@media screen and (max-width:770px){
    .ResumeStep_con{
        margin: 10px;
        padding: 10px 0;
    }
}
@media screen and (max-width:600px){
    .ResumeStep_con{
        margin: 0 !important;
        padding: 10px 0;
    }
    .MiddleSubHeading {
        line-height: 20px !important;
    }
}