/* .card_wrapper a{
    text-decoration: none;
    color:unset;
}

.card{
    border:1px solid #E8E8EA;
    border-radius: 16px !important;
}

.card:hover{
    background:unset !important;
    color:unset;
} */
.card_wrapper{
    position: relative;
}
.card_wrapper a{
    text-decoration: none;
    color:unset;
}

.card{
    border:1px solid #E8E8EA;
    /* position: relative; */
    /* position: relative; */
}

.card:hover{
    background:unset !important;
    color:unset;
}

.category{
    /* display: flex; */
    /* display: block; */
    width: 10vh;
    margin-left: 25px;
    color: blue;
    background-color: #F6F8FF;
    /* background-color: red; */
    padding: 6px;
    border-radius: 20%;
}

.source_img{
    width: 42px;
    height: 42px;
    border-radius: 50%;
    padding: 1px;
}

.top_img{
    object-fit: cover;
    width: 100%;
    max-height: 32vh;
    min-height: 32vh;
    padding: 5px;
    border-radius: 15px;
}

.upper_v_1 {
    display: flex !important;
    justify-content: end !important;
    color: white;
    position: absolute;
    top: 10px;
    right: 8px;
    z-index: 2;
  }
  
  .upper_v_1_1 {
    width: 30px;
    height: 32px;
    background: white;
    border-radius: 8px;
    box-shadow: inset 1px 1px 2px gray;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    font-weight: 500;
    color: black;
    writing-mode: vertical-lr;
    z-index: 999;
  }

  .main{
    margin: 12px 10px 30px 10px;
  }