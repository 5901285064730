.heading{
    font-size: 36px;
    font-weight: 600;
    color: black;
    text-align: center;
    font-family: Lufga;
}

.bgImg{
    background: url(/public/images/image_2024_03_05T12_37_23_785Z.png);
    background-size: cover;
    background-position: center;
    border-radius: 25px;
}


.SaveButton {
    border: 1px solid #187C8B;
    border: none;
    color: white;
    border-radius: 11px;
    padding: 15px 30px;
    background: #187C8B;
    font-weight: 500;
    text-align: center;
    font-size: 18px;
}

.closeIcon{
    font-size: 24px;
    font-weight: 700;
    color: #187c8b;
    cursor: pointer;
}

