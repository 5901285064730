  .LabelHeading {
    font-family: Lufga;
    /* font-size: 16px; */
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    color: #101828;
  }

  .favoriteContainer {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 24px;
  }

  .subHeading {
    color: rgba(16, 24, 40, 1);
    /* font-size: 16px; */
    font-family: lufga;
    font-weight: bold;
  }


  .wrapper_for_Addopting_parent {
    textarea {
      resize: none;
      overflow: hidden;
    }
  }


  @media (max-width : 1200px) {
    .favoriteContainer {
      /* display: grid; */
      grid-template-columns: repeat(3, 1fr);
      gap: 15px;
    }
  }

  @media (max-width : 576px) {
    .favoriteContainer {
      /* display: grid; */
      grid-template-columns: repeat(1, 1fr);
      gap: 12px;
    }
  }