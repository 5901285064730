
/* Universal scrollbar styling */
::-webkit-scrollbar {
  width: 4px;  /* Vertical scrollbar width */
  height: 4px; /* Horizontal scrollbar height */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar handle */
  border-radius: 6px;     /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color on hover for better visibility */
}

::-webkit-scrollbar-track {
  /* Background of the scrollbar track */
  /* background: #f1f1f1;     */
  border-radius: 6px;     /* Match the scrollbar thumb radius */
}

/* Optional: Style the scrollbar corner for overflow: scroll; elements */
::-webkit-scrollbar-corner {
  background-color: transparent; /* Make the corner transparent */
}


@font-face {
  font-family: 'Lufga';
  src: url('../public/fonts/Lufga-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import url('https://fonts.googleapis.com/css2?family=Volkhov:ital,wght@0,400;0,700;1,400;1,700&display=swap');


.volkhov-regular {
  font-family: "Volkhov", serif;
  font-weight: 400;
  font-style: normal;
}

.volkhov-bold {
  font-family: "Volkhov", serif;
  font-weight: 700;
  font-style: normal;
}

.volkhov-regular-italic {
  font-family: "Volkhov", serif;
  font-weight: 400;
  font-style: italic;
}

.volkhov-bold-italic {
  font-family: "Volkhov", serif;
  font-weight: 700;
  font-style: italic;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.radius-10px {
  border-radius: 10px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex {
  display: flex;
}

.align_center {
  align-items: center;
}

.justify_center {
  justify-content: center;
}

.end {
  justify-content: flex-end;
}

.between {
  justify-content: space-between;
}

.around {
  justify-content: space-around;
}

.pointer {
  cursor: pointer;
}

.p-relative {
  position: relative !important;
}

.no_underline {
  text-decoration: none;
}


/* [[[[[{{{{{{{{{{{===========  input css  ==========}}}}}}}}}}}}}}]]]] */

.custom_input {
  width: 100%;
  padding: 16px;
  border-radius: 12px;
  background: #FFF;
  color: var(--PT-80, #363F57);
  font-size: 14px;
  font-weight: 400;
  border: none;
}

.custom_input:focus {
  outline: 2px solid var(--CTA, #2AC37D);
  box-sizing: border-box;
}


.invalid_input {
  border: 2px solid red !important;
}

.error {
  color: red !important;
}

.active div {
  color: var(--PT-5, #F2F3F4);
}

.font_web {
  color: #101828;
}

/* +++++++++++++++++++++primereact classess css start ++++++++++++++++++++ */
.p-dialog-content {
  padding: 40px;
  border-radius: 6px;
}

.p-dialog-header {
  padding: 0;
}

.p-dropdown-panel .p-dropdown-items {
  padding: 0 !important;
}

/* +++++++++++++++++++++primereact classess css end ++++++++++++++++++++ */

.btn_secondary {
  border-radius: 8px;
  border: 1px solid var(--Secondary-Main, #101828);
  color: var(--Secondary-Main, #101828);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  background-color: white;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.p-dialog-content {
  padding: 40px;
}

.clinic_green {
  color: #004A46;
}
.lufga{
  font-family: Lufga;
}

.bg_primary {
  background: rgba(24, 124, 139, 1) !important;
}
.custom_select{
  height: 38px;
}

.btn-primary {
  background: #187C8B !important;
  padding: 21px 26px !important;
  border: none !important;
  border-radius: 12px !important;
  color: white !important;
  font-family: Lufga !important;
  font-size: 20 !important;
  font-weight: 500 !important
}

.btn_fill {
  color: white;
  background: rgba(24, 124, 139, 1);
  border: 1px solid rgba(24, 124, 139, 1);
  border-radius: 12px;
  padding: 20px 50px;
  font-weight: 500;
  font-size: 18px;
  font-family: Lufga !important;
}



.btn_non_fill {
  background: none;
  color: rgba(24, 124, 139, 1);
  border: none;
  border-radius: 12px;
  padding: 20px 50px !important;
  font-size: 18px;
  font-weight: 600;
  font-family: Lufga !important;
}

.PhoneInputInput {
  width: 100% !important;
  border: 1px solid rgb(177, 174, 174);
  padding: 5px 10px;
  line-height: 1.5;
  border-radius: 0.375rem;
}

.PhoneInputInput {
  width: 100% !important;
  border: 1px solid rgb(177, 174, 174);
  padding: 5px 10px;
  line-height: 1.5;
  border-radius: 0.375rem;
}

.PhoneInputInput:focus {
  border: 1px solid rgb(177, 174, 174);
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  outline: 0;
}
.multiSelectDropDown {
  width: 100%;
  padding: 10px auto !important;
  border: 1px solid rgb(177, 174, 174) !important;
}




@media screen and (max-width: 800px) {
  .btn_fill {
    color: white;
    background: rgba(24, 124, 139, 1);
    border: 1px solid rgba(24, 124, 139, 1);
    border-radius: 12px;
    padding: 10px 40px;
    font-weight: 500;
    font-size: 17px;
  }

  .btn_non_fill {
    background: none;
    color: rgba(24, 124, 139, 1);
    border: none;
    border-radius: 12px;
    padding: 10px 40px !important;
    font-size: 17px;
    font-weight: 600;
  }
  .btn-primary {
    padding: 10px 20px !important;
  }
}
@media screen and (max-width: 500px) {
  .btn_fill {
    color: white;
    background: rgba(24, 124, 139, 1);
    border: 1px solid rgba(24, 124, 139, 1);
    border-radius: 6px;
    padding: 5px 20px;
    font-weight: 400;
    font-size: 14px;
  }

  .btn_non_fill {
    background: none;
    color: rgba(24, 124, 139, 1);
    border: none;
    border-radius: 12px;
    padding: 5px 20px !important;
    font-weight: 500;
    font-size: 14px;
  }

  .btn-primary {
    padding: 5px 15px !important;
    font-size: 10px !important;
    border-radius: 5px !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}