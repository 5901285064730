.IntendedParents_con {
    overflow: hidden;

    .Mainheading {
        font-family: Lufga;
        font-size: 48px;
        font-weight: 500;
        line-height: 48px;
        letter-spacing: 0em;
        text-align: center;
        color: black;

    }

    .ImagesBackground {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .SurrogateColor {
        color: #187C8B;
        font-family: Lufga;
        font-size: 48px;
        font-weight: 500;
        line-height: 48px;
        letter-spacing: 0em;
        text-align: center;

    }

    .ImagesMain {
        display: flex;
        justify-content: center;
        align-items: center;


    }

    .textcustom {
        align-items: center;
        display: flex;

    }

    .ImagesComun {
        display: flex;

        gap: 30px;
    }

    .ImagesCustom2 {

        z-index: -1;
    }

    .BulidText {
        font-family: Lufga;
        font-size: 24px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;

        align-items: left;
        color: black;
    }

    .BoxColor {
        background-color: #E6F3F2;
        padding: 20px 0px;
        margin-bottom: 30px;
    }

    .BoxColor2 {
        background-color: #FFEAE9;
        padding: 20px 0px;

    }

    .backGroungImages {
        background-image: url(/public/images/image_2024_03_01T06_05_00_162Z.png);
        width: 100%;
        height: 100Vh;
        background-repeat: no-repeat;
        background-size: cover;
        /* Adjust the opacity value to darken or lighten */
    }

    .boxContent {

        position: relative;
    }

    .BoxColortwo {
        padding: 20px 0px;

        width: 100%;
        height: 100%;
        background-color: hsla(0, 0%, 0%, 0.692);
        translate: transform(100%, 0);
        /* position: absolute; */

        /* margin-bottom: 30px; */
    }

    .Modaldiv {
        background-color: rgb(255, 255, 255);
        position: absolute;
        top: 30%;
        margin-left: 15%;
        width: 70%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        box-shadow: #AEF2EF;
    }

    .SubsCriptiontext {
        font-family: Lufga;
        font-size: 32px;
        font-weight: 500;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
        display: flex;
        justify-content: center;

    }

    .LockImages {
        display: flex;
        justify-content: center;
    }

    .ViewSubscription {
        background-color: #187C8B;
        padding: 21px 26px;
        border: none;
        font-size: 20px;
        font-family: Lufga;
        font-weight: 500;
        border-radius: 12px;
        color: white;

    }
}



.DialogParent {
    width: 100%;
    font-family: 'Lufga', sans-serif;
    border-radius: 6px;

    .SignUphedaing {
        font-size: 2.25rem;
        font-weight: 700;
        color: #000000;
        font-family: 'Lufga', sans-serif;
        ;
    }


    .completeIcon{
        color: rgba(24, 124, 139, 1) !important;
        /* font-size: 1.8rem; */
    }


    .form-label {
        font-family: 'Lufga', sans-serif !important;
        font-size: 1rem;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.012em;
        text-align: left;
        color: #101828;

        ;



    }



    .GetCode {
        position: absolute;
        top: 6px;
        right: 10px;
        color: #187C8B;
        font-weight: 500;
        font-size: 1rem;
        z-index: 2;
        background: white;
    }

    .EyeView {
        position: relative;
    }

    .View {
        position: absolute;
        top: 5px;
        right: 10px;
    }

    .form-check-label {
        color: black;
        font-weight: 400;
        font-size: 1rem;
    }

    .form-control-placeholder::placeholder {
        font-size: 0.875rem;
    }

    .form-control {
        border: 1px solid rgb(177, 174, 174);
        padding: 5px 10px;
    }

    .form-select {
        border: 1px solid rgb(177, 174, 174);
    }

    .form-check-input {
        border: 1px solid rgb(177, 174, 174);
    }

    .ButtonForm {
        display: flex;
        justify-content: end;
        gap: 10px;
        margin-top: 30px;
    }

    .SaveButton {
        background-color: #187C8B;
        border-radius: 11px;
        padding: 8px 30px;
        color: white;
        font-weight: 500;
        text-align: center;
        border: none;
    }

    .CencelButton {
        background-color: transparent;
        border-radius: 11px;
        border: none;
        padding: 8px 25px;
        color: #187C8B;
        font-size: 1rem;
        font-weight: 500
    }

    .TermColor {
        color: #187C8B;
        font-size: 1rem;
        font-weight: 400;
        text-align: left;
    }

    .form-check-label {
        color: #000000;

        font-size: 1rem;
        font-weight: 400;

        text-align: left;

    }

    .TextCustomBottom {

        font-weight: 500;
        line-height: 24px;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 24px;
        color: black;



    }

    .Create {
        font-weight: 500;
        line-height: 24px;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 24px;
        color: #187C8B;

    }

    .spanaPara {
        font-weight: 500;
        line-height: 24px;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 24px;
    }

    .BackLogin {
        font-weight: 500;
        line-height: 24px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 24px;
        color: #187C8B;
    }

    .ForgetPassword {
        color: #187C8B;
        font-weight: 400;
    }

    .ButtonHowDoes{
        padding: 21px 26px;
        border-radius: 12px;
        background-color: #ba0000cd !important;
        font-family: Lufga;
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: center;
        color: white;
        border: none;
        margin-top: 20px;

    }

    @media only screen and (max-width: 640px) {

       

        .SignUphedaing {
            font-size: 2rem;
            font-weight: 500;
            color: #000000;
            font-family: 'Lufga', sans-serif;
            ;
        }

        .LabelHeading {
            font-family: Lufga;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.012em;
            text-align: left;
            color: #000000;

        }

    }



}
.LoaderCustom{
    background-color: unset !important;
    box-shadow: unset !important;
}

/* .p-dialog {
    background: transparent!;
    .p-dialog-content{
        background: transparent!;
    }
} */