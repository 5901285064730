


.upper_section {
    display: flex;
    background-position: center;
    height: unset;
    border-radius: 6.6rem 0px 6.6rem 0px;
    margin-top: unset;
}

.upper_section_image_couple {
    width: 45%;
}

.upper_section_content {
    width: 55%;
    background-image: unset;
}

.all_stories {
    padding-top: 4rem;
}

.any_question_section {
    /* height: 15rem; */
    background: #146497;
    margin-bottom: 8rem;
    border-radius: 20px;
    display: flex;
    justify-content: space-evenly;
    /* align-items: center; */
    position: relative;
    padding: 3rem;
}

.row_section {
    display: flex;
    justify-content: center;
}

.card_padding {
    padding: 0 7rem;
}

.left_of_any_question {
    width: 10%;
}

.left_of_any_question :nth-child(2) {
    text-align: right;
}

.right_of_any_question {
    width: 10%;
}


.right_of_any_question :nth-child(2n+1) {
    text-align: right;
}

.content_of_any_question div {
    text-align: center;
}

.input_width {
    width: 50%;
}




@media (max-width: 1000px) {


    .any_question_section {
        margin-top: 6rem;
    }

    .left_of_any_question img,
    .right_of_any_question img {
        width: 50%;
    }


    .input_width {
        width: 75%;
    }

    .all_stories .lufga_14px {
        font-size: 1.2rem;
    }

    .card_padding {
        padding: 2rem 7rem;
    }

}



@media (max-width: 800px) {
    .card_padding {
        padding: 2rem 5rem;
    }
}

@media (max-width: 450px) {
    .any_question_section {
        margin-top: 6rem;
    }

    .left_of_any_question,
    .right_of_any_question {
        display: none;
    }

    .input_width {
        width: 100% !important;
    }

    .card_padding {
        padding: 2rem 1rem;
    }

    .heading{
        display: none !important;
    }
}