.card_wrapper a{
    text-decoration: none;
    color:unset;
}

.card{
    border:1px solid #E8E8EA;
}

.card:hover{
    background:unset !important;
    color:unset;
}


.main_container{

    display: flex;
    margin-bottom: 4rem;
    padding:2rem 3rem 0 3rem;
}

.news_section{

    height: 100%;
    width: 70%;
}

.right_side_section{

    height: 100%;
    width: 30%;
}

.image_container{
    width: 100%;
    margin-top: 1rem;
    
}
.image_container img{
    width: 100%;
    border-radius: 0 0 10px 10px;
}

.news_content_section{
    width: 90%;
    display: flex;
    flex-direction: column;
    gap:1rem;
}

.lufga_16px{
    font-family: 'Lufga', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.375rem;
    letter-spacing: 0.5px;
    text-align: left;
}

.cards_container{
    background: #f5f5f5;
    border-radius: 12px;
    margin-left: 0.8rem;
    margin-bottom: 1rem;
    padding:0.9rem;
    display: flex;
    flex-direction: column;
    gap:0.9rem;
}

.card_one{
    display: flex;

}

.card_img_container{
    width: 20%;
}
.card_img_container img{
    border-radius: 10px;
    width: 100%;
}

.card_body{
    padding:0 0.3rem;
    width:80%;
}


@media screen and (max-width:450px) {
    .main_container{
        padding:2rem 0.5rem 0 3rem;
    }

    .card_one{
        flex-direction:column;
    }

    .card_img_container img{
        width:350%;
    }

    .right_side_section{
        width:40%;
    }

    .card_body{
        width:100%;
    }
}