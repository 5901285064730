.card_wrapper a{
    text-decoration: none;
    color:unset;
}


.card {
    border:1px solid #E8E8EA;
    width: 100% !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: unset;
    border-radius: 8px;
    gap: unset;
    box-shadow: 0px 3.5054876804351807px 14.021950721740723px 0px rgba(158, 158, 158, 0.25);
}

.card_img_top {
    border-radius: 0px !important;
}

.body_of_card {
    display: flex;
}

.card_body {
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    padding: 0;
}

.card:hover {
    background: unset !important;
    cursor: pointer;
    color: unset;
}


.all_stories a {
    display: inline-block;
    text-decoration: none;
    width: 100%;
    height: 100%;
}



.logo_of_card{
    padding:1rem;
  
   
}

.logo_of_card img{
    height: 30px;
}


.lower_lower {
    display: flex;
    justify-content: space-around;
}

.lower_lower a img {
    width: 100%;
}

.lower_lower img {
    width: 12%;
    cursor: pointer;
}

.heading{
    /* transform: translate(0px, -160%); */
    position: absolute;
    top:25%;
}

.wrapper_for_moving_line{
    position: relative;
    width: 100%; /* Adjust based on your needs */
    overflow: hidden; /* Hide overflow */
}

.moving_line{
    position: absolute;
    width: 100%; /* Line width */
    animation: slide 5s infinite linear;
}
@keyframes slide {
    0% {
      transform: translateX(90%);
    }
    100% {
      transform: translateX(-90%);
    }
  }


























.upper_section {
    display: flex;
    background-position: center;
    height: unset;
    border-radius: 6.6rem 0px 6.6rem 0px;
    margin-top: unset;
}

.upper_section_image_couple {
    width: 45%;
}

.upper_section_content {
    width: 55%;
    background-image: unset;
}

.all_stories {
    padding-top: 4rem;
}

.any_question_section {
    /* height: 15rem; */
    background: #146497;
    margin-bottom: 8rem;
    border-radius: 20px;
    display: flex;
    justify-content: space-evenly;
    /* align-items: center; */
    position: relative;
    padding: 3rem;
}

.row_section {
    display: flex;
    justify-content: center;
}

.card_padding {
    padding: 0 7rem;
}

.left_of_any_question {
    width: 10%;
}

.left_of_any_question :nth-child(2) {
    text-align: right;
}

.right_of_any_question {
    width: 10%;
}


.right_of_any_question :nth-child(2n+1) {
    text-align: right;
}

.content_of_any_question div {
    text-align: center;
}

.input_width {
    width: 50%;
}




@media (max-width: 1000px) {

    :root{
        font-size: 14px;
    }

    .any_question_section {
        margin-top: 6rem;
    }

    .left_of_any_question img,
    .right_of_any_question img {
        width: 50%;
    }


    .input_width {
        width: 75%;
    }

    .all_stories .lufga_14px {
        font-size: 1.2rem;
    }

    .card_padding {
        padding: 2rem 7rem;
    }

}

@media (max-width:888px) {
    :root{
        font-size: 12px;
    }
    
}

@media (max-width: 800px) {
    .card_padding {
        padding: 2rem 5rem;
    }
}

@media (max-width: 450px) {
    .any_question_section {
        margin-top: 6rem;
    }

    .left_of_any_question,
    .right_of_any_question {
        display: none;
    }

    .input_width {
        width: 100% !important;
    }

    .card_padding {
        padding: 2rem 1rem;
    }

    .heading{
        display: none !important;
    }
}

