.Payment_con{
    .PaymentMethodText{
        font-family: Lufga;
    font-size: 24px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
    color: #187C8B;
    
    
    }
    .GoldText{
        font-family: Lufga;
        font-size: 36px;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: 0em;
        text-align: center;
        color: black;
    
    }
    .CreaditCradText{
        font-family: Lufga;
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #202224;
    
    }
    .CraeditCardDiv{
        display: flex;
        flex-direction: column;
    }
    .accptCrads{
        font-family: Lufga;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #202224;
    }
    .form-check-input:checked[type=radio]{
        background-color:#187C8B;
    }
    .CancelPayButton{
        font-family: Lufga;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #187C8B;
    padding: 21px 26px;
    border-radius: 12px;
    border: none;
    background-color: transparent;
    
    }
    
    .PayButton{
        font-family: Lufga;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    background-color: #187C8B;
    width: 194px;
    border-radius: 12px;
    border: none;
    color: white;
    
    }
    .EyeView {
        position: relative;
    }
    
    .View {
        position: absolute;
        top: 33%;
        font-size: 20px;
        right: 10%;
    
    }
}